import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import ScrollToTop from "./components/ScrollToTop";
import Loader from "./components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { checkAuth } from "./redux/features/auth/authSlice";
import { getStaticData } from "./redux/features/cms/staticDataSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LanguageProvider } from "./components/LanguageProvider";

// Lazy load pages and components
const Homepage = lazy(() => import("./pages/Homepage"));
const About_us = lazy(() => import("./pages/About_us"));
const Blogs = lazy(() => import("./pages/Blogs"));
const Contact_us = lazy(() => import("./pages/Contact_us"));
const Gallery = lazy(() => import("./pages/Galleries"));
const Packages = lazy(() => import("./pages/Packages"));
const Privacy_policy = lazy(() => import("./pages/Privacy_policy"));
const Terms_conditions = lazy(() => import("./pages/Terms_conditions"));
const Book_service = lazy(() => import("./pages/Book_service"));
const Repair_car_quote = lazy(() => import("./pages/Repair_car_quote"));
const Myaccount = lazy(() => import("./pages/Myaccount"));
const Login = lazy(() => import("./pages/Login"));
const Reset_password = lazy(() => import("./pages/Reset_password"));
const Booking_view = lazy(() => import("./pages/Booking_view"));
const Booking_list = lazy(() => import("./pages/Booking_list"));
const Payment_getway = lazy(() => import("./pages/Payment_getway"));
const Offers = lazy(() => import("./pages/Offers"));
const ViewBlog = lazy(() => import("./pages/ViewBlog"));
const Service_view = lazy(() => import("./pages/Service_view"));
const Change_password = lazy(() => import("./pages/Change_password"));
const Payment_success = lazy(() => import("./pages/Payment_success"));
const NotFound = lazy(() => import("./pages/NotFound/NotFound"));
const Services = lazy(() => import("./pages/Services"));
const Country = lazy(() => import("./pages/Country"));

const PrivateRoute = lazy(() => import("./components/ProtectedRoutes"));
const LoginPrivateRoute = lazy(() => import("./components/LoginPrivateRoute"));
function App() {
  const dispatch = useDispatch();
  const static_data = useSelector((state) => state.staticData);

  useEffect(() => {
    dispatch(checkAuth())
      .then((res) => {
        const userToken = localStorage.getItem("userToken");
        if (!res.payload.success && userToken) {
          localStorage.clear();
          window.location.replace("/auth"); // Redirect to login
        }
      })
      .catch((error) => {
        console.error("Authentication check failed", error);
      });
  }, [dispatch]);

  useEffect(() => {
    if (static_data.loading === "PENDING") {
      dispatch(getStaticData());
    }
  }, [dispatch, static_data.loading]);

  if (static_data.loading === "PENDING") {
    return <Loader />;
  }

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <ScrollToTop />
        <LanguageProvider>
          <Layout {...static_data.data}>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={<Country />} />
                <Route path="/home" element={<Homepage />} />
                <Route path="/about" element={<About_us />} />
                <Route path="/blog" element={<Blogs />} />
                <Route path="/contact-us" element={<Contact_us />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/packages" element={<Packages />} />
                <Route path="/offers" element={<Offers />} />
                <Route path="/privacy-policy" element={<Privacy_policy />} />
                <Route path="/payment/:status" element={<Payment_success />} />
                <Route path="/blog/:id" element={<ViewBlog />} />
                <Route path="/terms-and-conditions" element={<Terms_conditions />} />
                <Route path="/repair-car-quote" element={<Repair_car_quote />} />
                <Route path="/services" element={<Services />} />
                <Route path="/services/:slug" element={<Service_view />} />
                <Route path="/profile" element={
                  <PrivateRoute>
                    <Myaccount />
                  </PrivateRoute>
                } />
                <Route path="/change-password" element={
                  <PrivateRoute>
                    <Change_password />
                  </PrivateRoute>
                } />
                <Route path="/payment-getway" element={
                  <PrivateRoute>
                    <Payment_getway />
                  </PrivateRoute>
                } />
                <Route path="/booking-service/:id" element={<Book_service />} />
                <Route path="/booking-list" element={
                  <PrivateRoute>
                    <Booking_list />
                  </PrivateRoute>
                } />
                <Route path="/booking-view/:id" element={
                  <PrivateRoute>
                    <Booking_view />
                  </PrivateRoute>
                } />
                <Route path="/auth" element={
                  <LoginPrivateRoute>
                    <Login />
                  </LoginPrivateRoute>
                } />
                <Route path="/reset-password" element={
                  <LoginPrivateRoute>
                    <Reset_password />
                  </LoginPrivateRoute>
                } />
                <Route path="page-not-found" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/page-not-found" replace />} />

              </Routes>
            </Suspense>
          </Layout>
        </LanguageProvider>
      </Router>
    </div>
  );
}

export default App;
