import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP_STATUS, API_URL, axiosConfig } from "../../../config/Constants";
import axios from "axios";
import Checkerror from "../../../components/Errors";

const namespace = "getAboutUsPageDataBySlug/about-us";


// Get banners
export const getAbout = createAsyncThunk(`${namespace}`, async () => {
  try {
    const { data } = await axios.get(`${API_URL}/${namespace}`, axiosConfig);
    return data;
  } catch (error) {
    return Checkerror(error);
  }
});

export const getAboutSlice = createSlice({
  name: namespace,
  initialState: {
    loading: "",
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getAbout.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [getAbout.fulfilled](state, action) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = action.payload;
    },
    [getAbout.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});

