import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP_STATUS, API_URL, axiosConfig, API_KEY } from "../../../config/Constants";
import axios from "axios";
import Checkerror from "../../../components/Errors";

const namespace = "register";

export const register = createAsyncThunk(
  `${namespace}`,
  async (formData) => {
    try {
      const { data } = await axios.post(
        `${API_URL}/${namespace}`,
        formData,
        axiosConfig
      );
      return data;
    } catch (error) {
      return Checkerror(error);
    }
  }
);

export const registerSlice = createSlice({
  name: namespace,
  initialState: {
    loading: "",
    data: [],
  },
  reducers: {},
  extraReducers: {
    [register.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [register.fulfilled](state, action) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = action.payload;
    },
    [register.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});

// registration verification
export const verifyOTP = createAsyncThunk(
  `/verifyOtp`,
  async (formData) => {
    try {
      const { data } = await axios.post(`${API_URL}/verifyOtp`, formData, axiosConfig);
      return data;
    } catch (error) {
      return Checkerror(error);
    }
  }
);

export const verifyOTPSlice = createSlice({
    name: namespace,
    initialState: {
      loading: "",
      data: [],
    },
    reducers: {},
    extraReducers: {
      [verifyOTP.pending](state, action) {
        state.loading = HTTP_STATUS.PENDING;
      },
      [verifyOTP.fulfilled](state, action) {
        state.loading = HTTP_STATUS.PENDING;
        },
      [verifyOTP.rejected](state, action) {
        state.loading = HTTP_STATUS.REJECTED;
      },
    },
  });




// Login
export const login = createAsyncThunk(
  `/login`,
  async (formData) => {
      const { data } = await axios.post(
        `${API_URL}/login`,
        formData,
        axiosConfig
      );
      if (data.data.token) {
        localStorage.setItem("userToken", data.data.token);
        localStorage.setItem("userData", data.data.userInfo.first_name_en+data.data.userInfo.last_name_en);
      }
      return data;
    
  }
);

export const loginSlice = createSlice({
  name: namespace,
  initialState: {
    loading: "",
    data: [],
  },
  reducers: {},
  extraReducers: {
    [login.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [login.fulfilled](state, action) {
      state.loading = HTTP_STATUS.PENDING;
           state.data = action.payload;

    },
    [login.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});


// Guest Login
export const guestlogin = createAsyncThunk(
  `${namespace}/guest_login`,
  async (formData) => {
    try {
      const { data } = await axios.post(
        `${API_URL}/${namespace}/guest_login`,
        formData,
        axiosConfig
      );
      if (data.token) {
        localStorage.setItem("userToken", JSON.stringify(data.token));
        localStorage.setItem("userData", data.firstname);
      }
      return data;
    } catch (error) {
      return Checkerror(error);
    }
  }
);

export const guestloginSlice = createSlice({
  name: namespace,
  initialState: {
    loading: "",
    data: [],
  },
  reducers: {},
  extraReducers: {
    [login.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [login.fulfilled](state, action) {
      state.loading = HTTP_STATUS.PENDING;
           state.data = action.payload;

    },
    [login.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});







  // reset password request of otp
  export const forgetPassword = createAsyncThunk(
    `/forgot-password`,
    async (formData) => {
      try {
        const { data } = await axios.post(`${API_URL}/forgot-password`, formData, axiosConfig);
        return data;
      } catch (error) {
        return Checkerror(error);
      }
    }
  );
  
  export const forgetPasswordSlice = createSlice({
      name: namespace,
      initialState: {
        loading: "",
        data: [],
      },
      reducers: {},
      extraReducers: {
        [forgetPassword.pending](state, action) {
          state.loading = HTTP_STATUS.PENDING;
        },
        [forgetPassword.fulfilled](state, action) {
          state.loading = HTTP_STATUS.PENDING;
          },
        [forgetPassword.rejected](state, action) {
          state.loading = HTTP_STATUS.REJECTED;
        },
      },
    });
    

    // reset password with verification of otp 
  export const resetPassword = createAsyncThunk(
    `/reset-password`,
    async (formData) => {
      try {
        const { data } = await axios.post(`${API_URL}/reset-password`, formData, axiosConfig);
        return data;
      } catch (error) {
        return Checkerror(error);
      }
    }
  );
  
  export const resetPasswordSlice = createSlice({
      name: namespace,
      initialState: {
        loading: "",
        data: [],
      },
      reducers: {},
      extraReducers: {
        [resetPassword.pending](state, action) {
          state.loading = HTTP_STATUS.PENDING;
        },
        [resetPassword.fulfilled](state, action) {
          state.loading = HTTP_STATUS.PENDING;
          },
        [resetPassword.rejected](state, action) {
          state.loading = HTTP_STATUS.REJECTED;
        },
      },
  });




    // Login check api

    export const checkAuth = createAsyncThunk(
      `/checkToken`,
      async () => {
        try {
          const userToken = localStorage.getItem("userToken");
          const { data } = await axios.get(`${API_URL}/checkToken`,{
            headers: {
              Authorization: `Bearer ${userToken}`, "token":API_KEY,
            },
          });
          return data;
        } catch (error) {
          return Checkerror(error);
        }
      }
    );
    
    export const checkAuthSlice = createSlice({
        name: namespace,
        initialState: {
          loading: "",
          data: [],
        },
        reducers: {},
        extraReducers: {
          [checkAuth.pending](state, action) {
            state.loading = HTTP_STATUS.PENDING;
          },
          [checkAuth.fulfilled](state, action) {
            state.loading = HTTP_STATUS.FULFILLED;
            state.data = action.payload
          },
          [checkAuth.rejected](state, action) {
            state.loading = HTTP_STATUS.REJECTED;
          },
        },
    });


    // logout api
    export const logoutAuth = createAsyncThunk(
      `/customer/logout`,
      async () => {
        try {
          const userToken = JSON.parse(localStorage.getItem("userToken"));
          const { data } = await axios.get(`${API_URL}/customer/login_check`,{
            headers: {
              Authorization: `Bearer ${userToken}`, "token":API_KEY
            },
          });
          return data;
        } catch (error) {
          return Checkerror(error);
        }
      }
    );
    
    export const logoutAuthSlice = createSlice({
        name: namespace,
        initialState: {
          loading: "",
          data: [],
        },
        reducers: {},
        extraReducers: {
          [logoutAuth.pending](state, action) {
            state.loading = HTTP_STATUS.PENDING;
          },
          [logoutAuth.fulfilled](state, action) {
            state.loading = HTTP_STATUS.FULFILLED;
            state.data = action.payload
          },
          [logoutAuth.rejected](state, action) {
            state.loading = HTTP_STATUS.REJECTED;
          },
        },
    });
