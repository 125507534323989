import React, {useContext} from "react";
import { Link, useLocation } from "react-router-dom";
import { LanguageContext } from "../components/LanguageProvider";


export default function Navbar() {
  let location = useLocation();
  const { selectedLanguage } = useContext(LanguageContext);

  return (
    <>
      <li className="nav-item">
        <Link
          to="/home"
          className={location.pathname === "/home" ? "nav-link active" : "nav-link"}
        >
          {selectedLanguage=='English'?'Home':'الصفحة الرئيسية'}
        </Link>
      </li>
      <li className="nav-item  ">
        <Link
          to="packages"
          className={
            location.pathname === "/packages" ? "nav-link active" : "nav-link"
          }
        >
          {selectedLanguage=='English'?'Packages':"الباقات"}

        </Link>
      </li>
      <li className="nav-item  ">
        <Link
          to="services"
          className={
            location.pathname === "/services" ? "nav-link active" : "nav-link"
          }
        >
          {selectedLanguage=='English'?'Services':'الخدمات'}
        </Link>
      </li>
      <li className="nav-item ">
        <Link
          to="about"
          className={
            location.pathname === "/about" ? "nav-link active" : "nav-link"
          }
        > 
          {selectedLanguage=='English'?'About Us':'نبذة عنا'}
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="contact-us"
          className={
            location.pathname === "/contact-us" ? "nav-link active" : "nav-link"
          }
        >
          
          {selectedLanguage=='English'?'Contact Us':'اتصل بنا'}

        </Link>
      </li>
      <li className="nav-item ">
        <Link
          to="gallery"
          // reloadDocument={true}
          className={
            location.pathname === "/gallery" ? "nav-link active" : "nav-link"
          }
        >
          
          {selectedLanguage=='English'?'Gallery':'المعرض'}

        </Link>
      </li>
      <li className="nav-item no-line ">
        <Link
          to="blog"
          className={
            location.pathname === "/blog" ? "nav-link active" : "nav-link"
          }
        >
          
          {selectedLanguage=='English'?'Blogs':'المدونات'}

        </Link>
      </li>
      
      
    </>
  );
}
