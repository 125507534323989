import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Parser from "html-react-parser";
import { getLanguage, API_URL, API_KEY } from "../config/Constants";
import axios from "axios";
import { LanguageConsumer } from "./LanguageProvider";

class Layout extends React.Component {
  state = {
    isOpen: true,
    isMobileNavOpen: false,
    footerData: "",
    sLang: "English",
    selectedLanguage: getLanguage(),
  };

  updateState = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      const response = await axios.get(`${API_URL}/geFooterData`, {
        headers: { token: API_KEY },
        maxBodyLength: Infinity,
      });
      this.setState({ footerData: response.data.data });
    } catch (error) {
      console.error("Error fetching footer data", error);
    }
  };

  handleMobileNavClick = () => {
    this.setState((prevState) => ({ isMobileNavOpen: !prevState.isMobileNavOpen }));
  };

  render() {
    const { footerData, sLang } = this.state;
    const userToken = localStorage.getItem("userToken");
    const userData = localStorage.getItem("userData");

    const logout = async () => {
      try {
        await axios.get(`${API_URL}/auth/logout`, {
          headers: { Authorization: `Bearer ${userToken}`, token: API_KEY },
        });
        localStorage.clear();
        toast.error("Logout success");
        window.location.reload();
      } catch (error) {
        console.error("Error during logout", error);
      }
    };

    const getFooterContent = (key, langKey) => {
      return sLang === "English" ? key[langKey + "_en"] : key[langKey + "_ar"];
    };

    const currentYear = new Date().getFullYear();
    const copyrightElement = `© ${currentYear} Autofix all rights reserved.`;

    return (
      <LanguageConsumer>
        {(context) => (
          <div className="page-wrapper" dir={sLang !== "English" ? "rtl" : "ltr"}>
            <ToastContainer className="custom-toast" />
            <h1 className="hidden" hidden>Autofix-Dubai</h1>
            <header className="sticky-header-top">
              <div className="header_top">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-12 max1200 min1200 d-block mx-auto">
                            <div className="d-flex justify-content-end py-1 pr-3 pr-xl-0">
                              <div className="mr-sm-4 bold-text small-text my-auto d-none d-sm-block">
                                <div className="mr-sm-4 bold-text small-text my-auto dropdown_wrapper">
                                  <select
                                    onChange={(event) => {
                                      context.changeLanguage(event.target.value);
                                      localStorage.setItem("lan", event.target.value);
                                      this.setState({ sLang: event.target.value });
                                    }}
                                    value={context.selectedLanguage}
                                  >
                                    <option value="English">English</option>
                                    <option value="Arabic">Arabic</option>
                                  </select>
                                </div>
                              </div>
                              <div className="mr-sm-4 bold-text small-text my-auto d-none d-sm-block">
                                <span>CALL : </span>
                                <Link>{footerData?.header_section?.category_title || "800 2886 349"}</Link>
                              </div>
                              <div className="mr-sm-4 bold-text small-text my-auto">
                                {userToken ? (
                                  <div className="mr-sm-4 bold-text small-text my-auto dropdown_wrapper">
                                    <a className="border-sm-left border-sm-right px-3">
                                      Hi, {userData === "undefined" ? "loading" : userData} &nbsp;
                                      <i className="fas fa-sort-down"></i>
                                    </a>
                                    <div className="hidden-inital dropdown_container dropdown_sm">
                                      <div className="dropdown_inner" onClick={this.handleMobileNavClick}>
                                        <ul>
                                          <Link to="profile">
                                            <li>Profile</li>
                                          </Link>
                                          <Link to="booking-list">
                                            <li>Bookings</li>
                                          </Link>
                                          <Link to="change-password">
                                            <li>Change Password</li>
                                          </Link>
                                          <li className="d-block text-center bg_light" onClick={logout}>
                                            Logout
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <Link to="auth" className="border-sm-left border-sm-right px-3 login-signup">
                                    {sLang === "English" ? "Login or Signup" : "تسجيل الدخول أو التسجيل"}
                                  </Link>
                                )}
                              </div>
                              <div className="social_icons_wrapper d-none d-sm-block">

                                <ul className="social-icon-four">

                                  <li>

                                    <a href={footerData?.get_in_touch?.content?.facebook_url || "#"} target="_blank" rel="noreferrer" aria-label="Facebook">
                                      <span className="fab fa-facebook-f"></span>

                                    </a>

                                  </li>

                                  <li>

                                    <a href={footerData?.get_in_touch?.content?.twitter_url || "#"} target="_blank" rel="noreferrer" aria-label="Twitter">
                                      <span className="fab fa-twitter"></span>
                                    </a>

                                  </li>

                                  <li>
                                    <a href={footerData?.get_in_touch?.content?.instagram_url || "#"} target="_blank" rel="noreferrer" aria-label="Instagram">

                                      <span className="fab fa-instagram"></span>

                                    </a>

                                  </li>

                                </ul>

                              </div>
                            </div>
                          </div>
                          <div className="col-12 border-bottom"></div>
                          <div className="col-12 max1200 min1200 d-block mx-auto d-view">
                            <nav className="navbar navbar-expand-lg navbar-light bg-custom nav_design_custom px-0 pr-3 pr-xl-0">
                              <Link className="navbar-brand logo_img" to="/home">
                                <img
                                  src="/assets/front/logo/bhawan5.png"
                                  className="img-fluid abs_logo"
                                  loading="lazy"
                                  alt="logo"
                                />
                              </Link>
                              <button
                                className="navbar-toggler"
                                type="button"
                                name="mobile-nav-toggle"
                                data-toggle="collapse"
                                data-target="#navbarNav"
                                aria-controls="navbarNav"
                                aria-expanded={!this.state.isMobileNavOpen ? "true" : "false"}
                                onClick={this.handleMobileNavClick}
                                aria-label="Toggle navigation"
                              >
                                <span className="navbar-toggler-icon"></span>
                              </button>
                              <div className={`collapse navbar-collapse px-3 px-lg-0 ${this.state.isMobileNavOpen && "hide"}`} id="navbarNav">
                                <ul className="navbar-nav ml-auto mr-0" onClick={this.handleMobileNavClick}>
                                  <Navbar />
                                  <li className="nav-item no-line d-none">
                                    <select
                                      className="nav-link mobile-view-language"
                                      onChange={(event) => {
                                        context.changeLanguage(event.target.value);
                                        localStorage.setItem("lan", event.target.value);
                                        this.setState({ sLang: event.target.value });
                                      }}
                                      value={context.selectedLanguage}
                                    >
                                      <option value="English">English</option>
                                      <option value="Arabic">Arabic</option>
                                    </select>
                                  </li>
                                </ul>
                                <div className="d-none">
                                  <div className="social_icons_wrapper d-none d-sm-block">
                                    <ul className="social-icon-four">
                                      <li>
                                        <a href="#" target="_blank">
                                          <span className="fab fa-facebook-f"></span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#" target="_blank">
                                          <span className="fab fa-twitter"></span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#" target="_blank">
                                          <span className="fab fa-instagram"></span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="mr-sm-4 bold-text small-text my-auto d-none">
                                  <span>CALL : </span>
                                  <Link>{footerData?.header_section?.category_title || "800 2886 349"}</Link>
                                </div>
                                <ul>
                                  <li className="nav-item no-line">
                                    <Link to="packages" className="nav-link btn btn-danger rounded btn-xs color-white">
                                      {context.selectedLanguage === "English" ? "Book Now" : "احجز الان"}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <main>{this.props.children}</main>
            {/* <span className="whatsappIconFixedIcon">
              <a
                className="whatsappIconLink p-2"
                target="_blank"
                rel="noopener noreferrer"
                href={footerData?.site_settings?.whats_app_number ? `https://wa.me/${footerData.site_settings.whats_app_number}` : "#"}
              >
                <img
                  alt="chat on whatsapp"
                  className="whatsappIcon whatsApp45 img-fluid"
                  src="/assets/front/uploads/about/whatsapp-logo-variant.svg"
                  loading="lazy"
                  height="34"
                  width="34"
                />
              </a>
            </span> */}
            <div className="modal fade" id="addressModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"></div>
            <footer className="main-footer footer_new">
              <div className="auto-container">
                <div className="widgets-outer">
                  <div className="widgets-section">
                    <div className="row clearfix">
                      <div className="footer-column col-lg-3 col-md-12 col-sm-12">
                        <div className="footer-widget logo-widget">
                          <div className="logo logo_footer mt-0">
                            <Link to="/">
                              {footerData?.auto_fix?.content && getFooterContent(footerData.auto_fix.content, "category_title")}
                              <br />
                              <span className="small-text">{footerData?.auto_fix?.content && Parser(getFooterContent(footerData.auto_fix.content, "highlight") || "")}</span>
                            </Link>
                          </div>
                          <div className="text color-white">{footerData?.auto_fix?.content && Parser(getFooterContent(footerData.auto_fix.content, "description") || "")}</div>
                        </div>
                      </div>
                      <div className="footer-column col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="footer-widget links-widget">
                          <h2 className="widget-title color-white">{footerData?.quick_links?.section_title && getFooterContent(footerData.quick_links.section_title, "category_title")}</h2>
                          <div className="widget-content white_dsg">
                            <ul className="list clearfix">
                              {footerData?.quick_links?.content?.map((link, linkIndex) => (
                                <li className="d-block" key={linkIndex}>
                                  <Link to={link.link}>{getFooterContent(link, "menu_name")}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="footer-column col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="footer-widget links-widget">
                          <h2 className="widget-title color-white">{footerData?.our_services?.section_title && getFooterContent(footerData.our_services.section_title, "category_title")}</h2>
                          <div className="widget-content white_dsg">
                            <ul className="list clearfix">
                              {footerData?.our_services?.content?.map((service, serviceIndex) => (
                                <li key={serviceIndex}>
                                  <Link to={`/services/${service.service_title_slug}`}>{getFooterContent(service, "title")}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="footer-column col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="footer-widget contact-widget white_dsg">
                          <h2 className="widget-title color-white">{footerData?.get_in_touch?.section_title && getFooterContent(footerData.get_in_touch.section_title, "category_title")}</h2>
                          <div className="widget-content">
                            <ul className="contact-info-list">
                              <li className="color-white">
                                <span className="fa fa-map-marker"></span>
                                {footerData?.get_in_touch?.content && Parser(getFooterContent(footerData.get_in_touch.content, "company_address") || "")}
                              </li>
                              <li>
                                <span className="fa fa-envelope-open"></span>
                                <a href={`mailto:${footerData?.get_in_touch?.content?.email || "#"}`}>{footerData?.get_in_touch?.content?.email || "#"}</a>
                              </li>
                              <li>
                                <span className="fa fa-phone"></span>
                                <a href={`tel:${footerData?.get_in_touch?.content?.contact_number || "#"}`}>{footerData?.get_in_touch?.content?.contact_number || "#"}</a>
                              </li>
                            </ul>
                          </div>
                          <ul className="social-icon-two">
                            <li>
                              <a href={footerData?.get_in_touch?.content?.facebook_url || "#"} target="_blank" rel="noreferrer">
                                <span className="fab fa-facebook-f"></span>
                              </a>
                            </li>
                            {/* <li>
                              <a href={`https://api.whatsapp.com/send?phone=${footerData?.site_settings?.whats_app_number || "#"}`} target="_blank" rel="noreferrer">
                                <span className="fab fa-whatsapp"></span>
                              </a>
                            </li> */}
                            <li>
                              <a href={footerData?.get_in_touch?.content?.instagram_url || "#"} target="_blank" rel="noreferrer">
                                <span className="fab fa-instagram"></span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="mx-auto">
                        <p className="text-center text-white">{copyrightElement}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
            <div className="scroll-to-top scroll-to-target" data-target="html">
              <span className="fa fa-angle-up"></span>
            </div>
          </div>
        )}
      </LanguageConsumer>
    );
  }
}

export default Layout;