import { combineReducers } from "@reduxjs/toolkit";

// login and register slices
import { registerSlice, loginSlice, guestloginSlice, checkAuthSlice, verifyOTPSlice, logoutAuthSlice } from "./auth/authSlice";
import { changePasswordSlice, getProfileSlice, updateProfileSlice } from "./profile/profileSlice";
import { getAddonSlice } from "./booking-data/addonSlice";
import { getLocationSlice } from "./booking-data/locationSlice";
import { getMakeSlice } from "./booking-data/makeSlice";
import { getPackageSlice } from "./booking-data/packagesSlice";
import { getStateSlice } from "./booking-data/stateSlice";
import { getTimeslotSlice } from "./booking-data/timeslotSlice";
import { getYearSlice } from "./booking-data/yearSlice";
import { getModalSlice } from "./booking-data/makeModelSlice";
import { getHomePackageSlice } from "./booking-data/homePackagesSlice";
import { submitContactSlice } from "./customer-requests/contactusSlice";
import { submitEnquirySlice } from "./customer-requests/enquirySlice";
import { resetPasswordSlice, forgetPasswordSlice } from "./auth/authSlice";
import { submitRepairEnquirySlice } from "./customer-requests/repairEnquirySlice";
import { getBookingSlice, getSingleBookingSlice, postBookingSlice } from "./booking-data/bookingSlice";
import { getMetaDataSlice, getStaticDataSlice } from "./cms/staticDataSlice";
import { getHomePageSlice } from "./cms/homeSlice";
import { getServiceSlice, getPopularServiceSlice, getServicesListSlice } from "./cms/serviceSlice";
import { getAboutSlice } from "./cms/aboutSlice";
import { getContactSlice } from "./cms/contactSlice";
import { getGallerySlice } from "./cms/gallerySlice";
import { getBlogSlice } from "./cms/blogSlice";
import { getSingleBlogSlice } from "./cms/blogSlice";
import { submitSubscribeSlice } from "./customer-requests/subscribeSlice";

// exporting slice to combined reducers
export default combineReducers({

    register:registerSlice.reducer,
    login:loginSlice.reducer,
    guestlogin:guestloginSlice.reducer,
    forgetPassword:forgetPasswordSlice.reducer,
    checkAuth:checkAuthSlice.reducer,
    resetPassword:resetPasswordSlice.reducer,
    getProfile:getProfileSlice.reducer,
    updateProfile:updateProfileSlice.reducer,

    

    // booking related reducers
    modal:getModalSlice.reducer,
    location:getLocationSlice.reducer,
    timeslot:getTimeslotSlice.reducer,
    year:getYearSlice.reducer,
    state:getStateSlice.reducer,
    addon:getAddonSlice.reducer,
    package:getPackageSlice.reducer,
    homePackage:getHomePackageSlice.reducer,
    // contact and enquiry requests
    submitContact:submitContactSlice.reducer,
    submitEnquiry:submitEnquirySlice.reducer,
    submitRepairEnquiry:submitRepairEnquirySlice.reducer,
    submitSubscribe:submitSubscribeSlice.reducer,

    // booking slice
    booking:postBookingSlice.reducer,
    getBooking:getBookingSlice.reducer,
    getSinglebookingDetails:getSingleBookingSlice.reducer,

    // Images
    // statis data
    staticData:getStaticDataSlice.reducer,
    metaData:getMetaDataSlice.reducer,
    
// ================================================================================== //
    // New
    homepage:getHomePageSlice.reducer,

    // booking data
    packages:getPackageSlice.reducer,
    make:getMakeSlice.reducer,
    service:getServiceSlice.reducer,
    serviceList:getServicesListSlice.reducer,
    about:getAboutSlice.reducer,
    contact:getContactSlice.reducer,
    gallery:getGallerySlice.reducer,
    getBlog:getBlogSlice.reducer,
    getSingleBlog:getSingleBlogSlice.reducer,
    changePassword:changePasswordSlice.reducer,
    verifyOTP:verifyOTPSlice.reducer,
    getPopularPackages:getPopularServiceSlice.reducer,
    getPopularServices:getPopularServiceSlice.reducer,
    logoutAuth:logoutAuthSlice.reducer
})