import { toast } from "react-toastify";

export default function Checkerror(error) {
  if (error.response.status === 401) {
    localStorage.clear(); // Clear localStorage
    // Redirect to login page or clear token
  } 
  if (error.response.status === 500) {
    toast.error("Server Error");
  }
  if (
    error.response.status === 400 ||
    error.response.status === 401 ||
    error.response.status === 422
  ) {
    if (error.response.data.success === false) {
      // Check if the response contains validation errors
      if (error.response.data.message === "Validation Error") {
        const validationErrors = error.response.data.data;
        for (const field in validationErrors) {
          const errorMessages = validationErrors[field];
          errorMessages.forEach((errorMessage) => {
            toast.error(errorMessage);
          });
        }
      } else {
        if (error.response.data.error === "Unauthenticated") {
          // Handle Unauthenticated error
          localStorage.clear(); // Clear localStorage
          // Optionally, you can refresh the page to force a logout
          // window.location.reload();
        } else {
          toast.error(error.response.data.message);
        }
      }
    }
  }
  return error.response.data;
}
