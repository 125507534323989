
var language='';

export const setLanguage = (language) => {
  language=localStorage.setItem('lan', language);
  return localStorage.getItem('lan') || 'English';
};

export {language};
// Function to get the selected language from localStorage
export const getLanguage = () => {
  return localStorage.getItem('lan') || 'English';
};

var lang_key='';
if(language=='Arabic'){
  lang_key='_ar';
}
export {lang_key}


var lang='en'
if(language=='Arabic'){
  lang='ar'
}
export const API_URL = "https://admin.autofix.ae/api";



// for reCaptcha
export const SITE_KEY="6LcwB8QjAAAAAJTz681s_NuEWCgLu-pyG3zhONA3"
export const SECRET_KEY="6LcwB8QjAAAAAK-PgX-mEGc4-pNTb4RSOuErAgUm"
export const REACT_CAPTCH="https://www.google.com/recaptcha/api/siteverify?secret="

export const ASSETS = "https://admin.autofix.ae/";
export const BLOG_ASSETS = "https://admin.autofix.ae/storage/images/blog/";
export const OFFERS = "https://admin.autofix.ae/storage/images/offers/";
export const API_KEY="a2b6c8e1f4g0i3j7k9m5n1o8p2q5r7t3u0v9w1x2Prashanthy4z6";
export const axiosConfig = {
  headers: {
    'token': API_KEY,
  },
};

export const PROFILE = `${ASSETS}/profile/`;


export const HTTP_STATUS = Object.freeze({
  PENDING: "PENDING",
  FULFILLED: "FULFILLED",
  REJECTED: "REJECTED",
});

export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
};


export const CURRENCY = "AED"
export const TAX_PERCENTAGE=5