import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP_STATUS, API_URL, API_KEY } from "../../../config/Constants";
import axios from "axios";
import Checkerror from "../../../components/Errors";

const namespace = "saveTheNewsLetter";

export const submitSubscribe= createAsyncThunk(
  `saveTheNewsLetter`,
  async (formData) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const { data } = await axios.post(`${API_URL}/saveTheNewsLetter`,formData,{
        headers: {
          Authorization: `Bearer ${userToken}`,
          "token":API_KEY
        },
      });
      return data;
    } catch (error) {
      return Checkerror(error);
    }
  }
);

export const submitSubscribeSlice = createSlice({
    name: namespace,
    initialState: {
      loading: "",
      data: [],
    },
    reducers: {},
    extraReducers: {
      [submitSubscribe.pending](state, action) {
        state.loading = HTTP_STATUS.PENDING;
      },
      [submitSubscribe.fulfilled](state, action) {
        state.loading = HTTP_STATUS.FULFILLED;
        state.data = action.payload
      },
      [submitSubscribe.rejected](state, action) {
        state.loading = HTTP_STATUS.REJECTED;
      },
    },
  });



  

    
  