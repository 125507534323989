import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP_STATUS, API_URL, axiosConfig } from "../../../config/Constants";
import axios from "axios";
import Checkerror from "../../../components/Errors";
const namespace_one = "getBlogPageDataBySlug/blogs";
const namespace_two = "getBlogDetailsBySlug";
const namespace_three = "getPopularBlogsData";

// Updated getBlog to accept pagination parameters
export const getBlog = createAsyncThunk(`${namespace_one}`, async ({ page, limit }) => {
  try {
    const { data } = await axios.get(`${API_URL}/${namespace_one}?page=${page}&limit=${limit}`, axiosConfig);
    return data;                
  } catch (error) {
    return Checkerror(error);
  }
});

export const getBlogSlice = createSlice({
  name: namespace_one,
  initialState: {
    loading: "",
    data: [],
    page: 1,
    limit: 9,
    totalPages: 0,
  },
  reducers: {},
  extraReducers: {
    [getBlog.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [getBlog.fulfilled](state, action) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = action.payload.data;
      state.totalPages = action.payload.totalPages;
    },
    [getBlog.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});

// ======================================================== //

// Updated getSingleBlog to accept pagination parameters
export const getSingleBlog = createAsyncThunk(
  `${namespace_two}/`,
  async (slug) => {
    try {
      const { data } = await axios.get(`${API_URL}/${namespace_two}/` + slug, axiosConfig);
      return data;
    } catch (error) {
      return Checkerror(error);
    }
  }
);

export const getSingleBlogSlice = createSlice({
  name: namespace_two,
  initialState: {
    loading: "",
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getSingleBlog.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [getSingleBlog.fulfilled](state, action) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = action.payload;
    },
    [getSingleBlog.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});

// popular blog//
export const getPopularBlog = createAsyncThunk(
  `${namespace_three}/`,
  async () => {
    try {
      const { data } = await axios.get(`${API_URL}/${namespace_three}`, axiosConfig);
      return data;
    } catch (error) {
      return Checkerror(error);
    }
  }
);

export const getPopularBlogSlice = createSlice({
  name: namespace_three,
  initialState: {
    loading: "",
    data: [],
    page: 1,
    limit: 9,
    totalPages: 0,
  },
  reducers: {},
  extraReducers: {
    [getPopularBlog.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [getPopularBlog.fulfilled](state, action) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = action.payload.data;
      state.totalPages = action.payload.totalPages;
    },
    [getPopularBlog.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});