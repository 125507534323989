import React from "react";
import "./loader.css"; // You can keep your existing styles

const Loader = () => (
  <div className="fallback-spinner">
    <div className="loading">
      <img src="/assets/front/loader/car-loader.gif" alt="Loading..." title="Loading" />
    </div>
  </div>
);

export default Loader;
