import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP_STATUS, API_URL, API_KEY } from "../../../config/Constants";
import axios from "axios";
import Checkerror from "../../../components/Errors";
import { toast } from "react-toastify";

const namespace = "auth";

export const getProfile = createAsyncThunk(
  `${namespace}/getUserProfile`,
  async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const { data } = await axios.get(`${API_URL}/${namespace}/getUserProfile`,{
        headers: {
          Authorization: `Bearer ${userToken}`,
          "token":API_KEY
        },
      });
      return data;
    } catch (error) {
      if(error.response.status != 200){
      return Checkerror(error);
      }
    }
  }
);

export const getProfileSlice = createSlice({
    name: namespace,
    initialState: {
      loading: "",
      data: [],
    },
    reducers: {},
    extraReducers: {
      [getProfile.pending](state, action) {
        state.loading = HTTP_STATUS.PENDING;
      },
      [getProfile.fulfilled](state, action) {
        state.loading = HTTP_STATUS.FULFILLED;
        state.data = action.payload
      },
      [getProfile.rejected](state, action) {
        state.loading = HTTP_STATUS.REJECTED;
      },
    },
  });




  //------------------update profile------------------------------//
  export const updateProfile = createAsyncThunk(
    `${namespace}/update-profile`,
    async (formData) => {
      try {
        const userToken = localStorage.getItem("userToken");
        const { data } = await axios.post(`${API_URL}/${namespace}/update-profile`, formData,{
          headers: {
            Authorization: `Bearer ${userToken}`,
            "token":API_KEY
          },
        });
        toast.success(data.message)
        return data;
      } catch (error) {
        return Checkerror(error);
      }
    }
  );
  
  export const updateProfileSlice = createSlice({
      name: namespace,
      initialState: {
        loading: "",
        data: [],
      },
      reducers: {},
      extraReducers: {
        [updateProfile.pending](state, action) {
          state.loading = HTTP_STATUS.PENDING;
        },
        [updateProfile.fulfilled](state, action) {
          state.loading = HTTP_STATUS.PENDING;
          },
        [updateProfile.rejected](state, action) {
          state.loading = HTTP_STATUS.REJECTED;
        },
      },
    });
    
  

    //----------------Change Password----------------------------//
    export const changePassword = createAsyncThunk(
      `${namespace}/changeUserPassword`,
      async (formData) => {
        try {
          const userToken = localStorage.getItem("userToken");
          const { data } = await axios.post(`${API_URL}/${namespace}/changeUserPassword`, formData,{
            headers: {
              Authorization: `Bearer ${userToken}`,
              "token":API_KEY
            },
          });
          return data;
        } catch (error) {
          return Checkerror(error);
        }
      }
    );
    
    export const changePasswordSlice = createSlice({
        name: namespace,
        initialState: {
          loading: "",
          data: [],
        },
        reducers: {},
        extraReducers: {
          [changePassword.pending](state, action) {
            state.loading = HTTP_STATUS.PENDING;
          },
          [changePassword.fulfilled](state, action) {
            state.loading = HTTP_STATUS.PENDING;
            },
          [changePassword.rejected](state, action) {
            state.loading = HTTP_STATUS.REJECTED;
          },
        },
      });

  
    
  