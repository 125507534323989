import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client'; // Updated import
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store/store';
import { Provider } from 'react-redux';
import Loader from './components/loader/Loader';

const root = ReactDOM.createRoot(document.getElementById('root')); // No change needed here

const RootComponent = () => (
  <Suspense fallback={<Loader />}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>
);

root.render(<RootComponent />);

reportWebVitals();